<template>
  <Container class="d-block">
    <div class="d-flex justify-space-between pr-3 pl-3 mb-1">
      <ClinicDetail flexDirection="column" style="width: 50%">
        <span class="normal__case w-100">{{ clinic.name }}</span
        >
        <span class="normal__case w-100">{{ clinic.address }}</span
        >
        <span class="normal__case w-100">{{ clinic.hotline }}</span
        >
      </ClinicDetail>
      <ImageLogoBox>
        <img
          class="imageLogoBox_Image"
          src="../../assets/CT_logo.png"
          alt="Logo Cao thang"
        />
      </ImageLogoBox>
    </div>

    <!-- <p class="text-center sub mp-0 mt-2 mb-1">---oOo---</p> -->
    <TitlePrescription>
      <span>{{ convertLangTitle(titlePrinter) }}</span>
    </TitlePrescription>

    <PatientInfo v-if="shortDetailPatient" class="mt-1">
      <div class="d-flex justify-space-between w-100 pl-3 pr-3">
        <div class="d-flex flex-column text-left">
          <label class="patientInfo__item"
            >{{ $t("print.fullName") }}:
            <span class="upper__case">{{ patientInfo.fullName }}</span></label
          >
          <label class="patientInfo__item"
            >{{ $t("print.gender") }}:
            <span class="bold__case">{{ patientInfo.gender }}</span></label
          >
          <label class="patientInfo__item"
            >{{ $t("print.address") }}:
            <span class="bold__case">{{ patientInfo.address }}</span></label
          >
        </div>

        <div class="d-flex flex-column text-right">
          <label class="patientInfo__item"
            >{{ $t("print.profileID") }}:
            <span class="bold__case">{{ patientInfo.patientID }}</span></label
          >
          <span class="patientInfo__item"
            >{{ $t("print.dateOfBirth") }}:

            {{ patientInfo.birthDate }}

            <b class="bold__case"
              >({{ $t("print.age") }}: {{ patientInfo.age }})</b
            >
          </span>
        </div>
      </div>

      <!-- <v-row class="mp-0 mt-1">
        <v-col cols="2" class="mp-0 text-right">Fullname:</v-col>
        <v-col cols="10" class="mp-0 text-left">{{
          patientInfo.fullName
        }}</v-col>
      </v-row>
      <v-row class="mp-0 mt-1">
        <v-col cols="2" class="mp-0 text-right">Phone:</v-col>
        <v-col cols="4" class="mp-0 text-left">{{ patientInfo.phone }}</v-col>
        <v-col cols="2" class="mp-0 text-right">Sex:</v-col>
        <v-col cols="4" class="mp-0 text-left">{{ patientInfo.gender }}</v-col>
      </v-row>
      <v-row class="mp-0 mt-1">
        <v-col cols="2" class="mp-0 text-right">Email:</v-col>
        <v-col cols="4" class="mp-0 text-left">{{ patientInfo.email }}</v-col>
        <v-col cols="2" class="mp-0 text-right">Birthdate:</v-col>
        <v-col cols="4" class="mp-0 text-left"
          >{{ patientInfo.birthDate }}
          <b>({{ patientInfo.age }} ages)</b></v-col
        >
      </v-row>
      <v-row class="mp-0 mt-1">
        <v-col cols="2" class="mp-0 text-right">Address:</v-col>
        <v-col cols="10" class="mp-0 text-left">{{
          patientInfo.address
        }}</v-col>
      </v-row> -->
    </PatientInfo>
    <DetailPrescription>
      <router-view @change-detail="change"></router-view>
    </DetailPrescription>
  </Container>
</template>

<script>
import { mapState } from "vuex";
import i18n from "@/plugins/i18n";

import {
  Container,
  TitlePrescription,
  ClinicDetail,
  PatientInfo,
  DetailPrescription,
  ImageLogoBox,
  // FooterPrescription
} from "./css/style";
export default {
  name: "PrescriptionLayout",

  components: {
    Container,
    TitlePrescription,
    ClinicDetail,
    PatientInfo,
    DetailPrescription,
    ImageLogoBox,
    // FooterPrescription
  },

  computed: {
    ...mapState("Patient", ["patientInfo"]),
    ...mapState("Clinic", ["brand", "clinic"]),
  },
  created() {
    this.titlePrinter = this.$route.name;
    console.log(this.patientInfo);
  },
  data: () => ({
    titlePrinter: "",
    shortDetailPatient: true,
  }),
  methods: {
    change(e) {
      this.shortDetailPatient = e.detail;
    },
    convertLangTitle(text) {
      var val = "";
      switch (text) {
        case "Đơn kính":
          val = i18n.t("print.glassPrescription");
          break;
        case "Đơn thuốc":
          val = i18n.t("print.medicinePrescription");
          break;
        case "Đơn Kính Áp Tròng":
          val = i18n.t("print.contactLensPrescription");
          break;
        case "Bản tóm tắt xuất viện":
          val = i18n.t("print.dischargeSummary");
          break;
        case "payment Summary":
          val = i18n.t("print.paymentSummary");
      }
      return val;
    },
  },
};
</script>

<style scoped>
@media print {
  .mp-0 {
    margin: 0px;
    padding: 0px;
  }
  /* .text-right {
    padding-right: 10px;
    font-weight: 450;
  }
  .text-left {
    width: 100%;
    display: inline-block;
    border-bottom: 1px dotted #bdbdbd;
    padding-bottom: 2px;
  } */
}
</style>